<template>
  <div id="user.login.index">
      <b-row class="m-0 p-4">
        <b-col md="3"></b-col>
        <b-col md="6">
          <b-card>
            <login-form />
          </b-card>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loginForm from "./form";
export default {
  name: "user.login.index",
  components: {
    loginForm,
  },
  computed: {
    ...mapGetters({
      authenticated: "isUserAuthenticated",
    }),
  },
  created() {
    if (this.authenticated) {
      this.$router.go(-1)
    }
  },
};
</script>

<style>
</style>