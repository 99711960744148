<template>
  <div id="user.form.login.password">
    <b-form-group class="mb-2" id="name-group">
      <label for="password-live">{{$t('user.form.login.password.label')}}:</label>
      <b-input-group>
        <b-form-input
          id="password-live"
          type="password"
          v-model="$v.password.$model"
          :state="validateState('password')"
          aria-describedby="password-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.password.$anyError" id="password-feedback">
        <small
          v-if="!$v.password.required"
          class="form-text text-danger"
        >{{$t('user.form.login.password.required')}}</small>
        <small
          v-if="!$v.password.minLength"
          class="form-text text-danger"
        >{{$t('user.form.login.password.minLength', { min: getLowerBoundary() })}}</small>
        <small
          v-if="!$v.password.maxLength"
          class="form-text text-danger"
        >{{$t('user.form.login.password.maxLength', { max: getUpperBoundary() })}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import logic from '@/scripts';
export default {
  name: "user.form.login.password",
  computed: {
    ...mapGetters({
      form: 'getLoginForm'
    }),
    password: {
      get() {
        return this.form.password
      },
      set(value) {
        this.$store.commit("setLoginFormPassword", value)
      }
    },
  },
  
  validations: {
    password: {
      required,
      minLength() {
        return (
          logic.input.user.login.password.minLength(this.form.password)
        );
      },
      maxLength() {
        return (
          logic.input.user.login.password.maxLength(this.form.password)
        );
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getLowerBoundary() {
      return logic.input.user.login.password.min
    },
    getUpperBoundary() {
      return logic.input.user.login.password.max
    },
  }
};
</script>

<style>
</style>