<template>
  <div id="login.form">
    <b-row v-if="this.message">
      <b-col md="12">
        <b-alert show variant="danger" class="text-center">{{
          this.message
        }} <br>
        If your account is not yet activated resend the code via <b-link :to="{name: 'activate.resend'}">here</b-link>
        </b-alert>
      </b-col>
    </b-row>
    <email />
    <password />
    <p class="text-muted">Password <b-link :to="{name: 'password.forgotten'}">forgotten</b-link>?</p>
    <b-row>
      <b-col class="d-flex mt-2 justify-content-center"
        ><login-button :active="valid"
      /></b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex mt-2 justify-content-center"
        ><p class="text-muted">
          Don't have an account yet?
          <b-link :to="{ name: 'register' }">Register</b-link>
        </p></b-col
      >
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import email from "../form/login/email";
import password from "../form/login/password";
import loginButton from "../components/loginButton";
import logic from "@/scripts";
export default {
  name: "register.form",
  components: {
    email,
    password,
    loginButton,
  },
  computed: {
    ...mapGetters({
      form: "getLoginForm",
      message: "getLoginMessage",
    }),
    valid() {
      return logic.input.user.login.validate(
        this.form.email,
        this.form.password
      );
    },
  },
};
</script>