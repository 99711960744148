<template>
  <div id="user.form.login.email">
    <b-form-group class="mb-2" id="email-group">
      <label for="email-live">{{$t('user.form.login.email.label')}}:</label>
      <b-input-group>
        <b-form-input
          id="email-live"
          type="text"
          v-model="$v.email.$model"
          :state="validateState('email')"
          aria-describedby="email-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.email.$anyError" id="email-feedback">
        <small
          v-if="!$v.email.required"
          class="form-text text-danger"
        >{{$t('user.form.login.email.required')}}</small>
        <small
          v-if="!$v.email.emailFormat"
          class="form-text text-danger"
        >{{$t('user.form.login.email.email')}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import logic from '@/scripts';
export default {
  name: "user.form.login.email",
  computed: {
    ...mapGetters({
      form: 'getLoginForm'
    }),
    email: {
      get() {
        return this.form.email
      },
      set(value) {
        this.$store.commit("setLoginFormEmail", value)
      }
    },
  },
  
  validations: {
    email: {
      required,
      emailFormat() {
        return (
          logic.input.user.login.email.emailFormat(this.form.email)
        );
      }
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>

<style>
</style>